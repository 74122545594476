<template>
  <b-card no-body>
    <div v-if="!$wait.is('loading-get-setting')">
      <b-row no-gutters>
        <b-col
          cols="12"
          class="bg-primary"
        >
          <div class="d-flex flex-row justify-content-between align-items-center">
            <div>
              <b-button
                variant="primary"
                class="btn-icon"
                :disabled="$wait.is('loading-on-save')"
                @click="$router.go(-1)"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                {{ this.$t('Previous Page') }}
              </b-button>
            </div>
            <div class="card-header text-white font-weight-bold h4">
              {{ this.$t('Account Info') }}
            </div>
            <div class="pr-1">
              <b-button
                variant="dark"
                class="btn-icon"
                size="lg"
                :disabled="$wait.is('loading-on-save')"
                @click="onAccountSave"
              >
                <feather-icon icon="SaveIcon" />
                {{ textButtonSave }}
              </b-button>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          class="p-2"
        >
          <AccountsCreateForm
            ref="formData"
            :form-data.sync="formData"
            :current-agent-type="GetAgentGroupType(userData)"
            :generate-account-name="generateAccountName"
            @onClickGenerateAccount="onClickGenerateAccount"
          />
        </b-col>
      </b-row>
      <b-row
        no-gutters
      >
        <b-col
          cols="12"
          class="bg-primary"
        >
          <div class="card-header text-white font-weight-bold h4">
            {{ this.$t('Setting Games') }}
          </div>
        </b-col>
        <b-col
          cols="12"
          class="p-2 setting-games"
        >
          <AccountFormSettingGamesComponent />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <loading />
    </div>
  </b-card>
</template>

<script>
import { getUserData } from '@/auth/utils'
import AccountsCreateForm from '@/views/accounts/AccountsCreateForm'
import AccountFormSettingGamesComponent from '@/components/settings/AccountFormSettingGamesComponent'
import store from '@/store'
import {
  AgentDownlineListsByCurrentType, GetAgentGroupId, GetAgentGroupUsername, GetAgentGroupType,
} from '@/utils/agent'
import { get, omit } from 'lodash'

const userDataFromLocalStorage = getUserData()

export default {
  components: {
    AccountsCreateForm,
    AccountFormSettingGamesComponent,
  },
  data() {
    return {
      generateAccountName: '',

      formData: {
        username: '',
        contact: '',
        currency: 'THB',
        password: '',
        creditLimit: 0,
        maxCreditLimit: 0,
        createNewType: Array.isArray(AgentDownlineListsByCurrentType(GetAgentGroupType(userDataFromLocalStorage))) && Array.from(AgentDownlineListsByCurrentType(GetAgentGroupType(userDataFromLocalStorage))).length > 0 ? String(AgentDownlineListsByCurrentType(GetAgentGroupType(userDataFromLocalStorage))[0]).toLowerCase() : '',
        requireChangePassword: true,
        active: true,
      },

      editId: '',
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
    currentAgentType() {
      return this.GetAgentGroupType(this.userData)
    },
    setting() {
      return store.getters['accountCreateSetting/getSetting']
    },
    settingCommission() {
      return store.getters['accountCreateSetting/getSettingCommission']
    },
    settingLimit() {
      return store.getters['accountCreateSetting/getSettingLimit']
    },
    settingType() {
      return store.getters['accountCreateSetting/getSettingType']
    },
    textButtonSave() {
      return this.settingType === 'NEW' ? this.$t('Create Account') : this.$t('Save')
    },
  },
  mounted() {
    const type = this.$route.name === 'accounts-edit' ? 'EDIT' : 'NEW'
    store.commit('accountCreateSetting/UPDATE_SETTING_TYPE', type)
    if (this.settingType === 'EDIT') {
      this.editId = this.$route.params.id
    }
    this.initFormData()
    this.getSetting()
  },
  methods: {
    GetAgentGroupId,
    GetAgentGroupUsername,
    GetAgentGroupType,
    initFormData() {

    },
    async getSetting() {
      this.$wait.start('loading-get-setting')
      try {
        const path = this.settingType === 'NEW' ? '/agent/accounts/group/init-setting' : `/agent/accounts/group/init-setting/${this.editId}`
        const { data } = await this.$http.get(path, {
          params: {
            type: "AGENT",
          },
        })
        if (data.success) {
        // cloneObject
          const userSetting = JSON.parse(JSON.stringify(data.data.user.setting));
          const userSettingCommission = JSON.parse(JSON.stringify(data.data.user.settingCommission));
          const userSettingLimit = JSON.parse(JSON.stringify(data.data.user.settingLimit));
          const initCommissionFrontend = JSON.parse(JSON.stringify(data.data?.initCommissionFrontend ?? {}));

          store.commit('accountCreateSetting/INIT_COMMISSION_FRONTEND', initCommissionFrontend)

          store.commit('accountCreateSetting/INIT_SETTING', userSetting)
          store.commit('accountCreateSetting/INIT_SETTING_COMMISSION', userSettingCommission)
          store.commit('accountCreateSetting/INIT_SETTING_LIMIT', userSettingLimit)

          store.commit('accountCreateSetting/UPDATE_SETTING', data.data.user.setting)
          store.commit('accountCreateSetting/UPDATE_SETTING_COMMISSION', data.data.user.settingCommission)
          store.commit('accountCreateSetting/UPDATE_SETTING_LIMIT', data.data.user.settingLimit)

          if (this.settingType === 'NEW') {
            store.commit('accountCreateSetting/UPDATE_UPLINE_SETTING', { ...userSetting })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_COMMISSION', { ...userSettingCommission })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_LIMIT', { ...userSettingLimit })
          } else {
            const uplineSetting = JSON.parse(JSON.stringify(data.data.user.upline.setting));
            const uplineSettingCommission = JSON.parse(JSON.stringify(data.data.user.upline.settingCommission));
            const uplineSettingLimit = JSON.parse(JSON.stringify(data.data.user.upline.settingLimit));

            store.commit('accountCreateSetting/UPDATE_UPLINE_SETTING', { ...uplineSetting })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_COMMISSION', { ...uplineSettingCommission })
            store.commit('accountCreateSetting/UPLINE_INIT_SETTING_LIMIT', { ...uplineSettingLimit })
          }

          store.commit('accountCreateSetting/UPDATE_SETTING_STATUS', data.data.upline_status)
          store.commit('accountCreateSetting/UPDATE_SETTING_AGENT_PREFIX', data.data.prefix)
          store.commit('accountCreateSetting/UPDATE_SETTING_IS_MEMBER', false)

          const { user } = data.data

          if (this.settingType === 'NEW') {
            const getType = AgentDownlineListsByCurrentType(GetAgentGroupType(this.userData))
            const createNewType = Array.isArray(getType) && Array.from(getType).length > 0 ? String(getType[getType.length - 1]).toLowerCase() : ''

            this.formData = {
              username: '',
              contact: '',
              currency: 'THB',
              password: '',
              creditLimit: 0,
              maxCreditLimit: 0,
              createNewType,
              requireChangePassword: true,
              active: true,
            }
          } else {
            this.formData = {
              username: user.username,
              contact: user.contact,
              currency: user.currency,
              password: '',
              creditLimit: user.creditLimit,
              maxCreditLimit: user.maxCreditLimit,
              createNewType: GetAgentGroupType(user),
              requireChangePassword: user.requireChangePassword,
              active: user.active,
            }
          }
        }
      } finally {
        this.$wait.end('loading-get-setting')
      }
    },
    async onAccountSave() {
      const isValidForm = await this.$refs.formData.validationForm()
      if (!isValidForm) {
        return
      }

      const { formData } = this

      this.$wait.start('loading-on-save')
      try {
        const path = this.settingType === 'NEW' ? '/agent/accounts/create' : `/agent/accounts/edit`

        let body = {}
        if (this.settingType === 'NEW') {
          body = {
            setting: this.setting,
            settingCommission: this.settingCommission,
            settingLimit: this.settingLimit,
            ...formData,
          }
        } else {
          body = {
            setting: this.setting,
            settingCommission: this.settingCommission,
            settingLimit: this.settingLimit,
            //   settingType: this.settingType,
            ...omit(formData, ['createNewType', 'requireChangePassword']),
            id: this.editId,
          }
        }

        const { data } = await this.$http.post(path, body)
        if (data.success) {
          this.$swal({
            icon: 'success',
            title: this.$t('ACCOUNT_SAVE_SUCCESS'),
          })

          if (this.settingType === 'NEW') {
            this.$router.push({ name: 'accounts' })
          }
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } finally {
        this.$wait.end('loading-on-save')
      }
    },
    async onClickGenerateAccount() {
      this.$wait.start('loading-on-account-generate')
      try {
        const { data } = await this.$http.post('/agent/accounts/generate', {
          type: String(this.formData.createNewType).toUpperCase(),
        })
        if (data.success) {
          this.generateAccountName = data.data.username
          this.formData.username = this.generateAccountName
        } else {
          this.$swal({
            icon: 'error',
            title: this.$t(data.status.message || 'Error'),
          })
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message || 'Error'),
        })
      } finally {
        this.$wait.end('loading-on-account-generate')
      }
    },
  },
}
</script>

<style>
.setting-games {
    min-height: 800px;
}
</style>
