var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"autocomplete":"off"}},[_c('input',{attrs:{"type":"password","name":"pwd","autocomplete":"new-password","hidden":""}}),_c('b-row',[(_vm.settingType === 'NEW')?_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Level'))+": ")]),_c('b-form-radio-group',{staticClass:"py-1",attrs:{"options":_vm.optionsTypes},on:{"change":_vm.onChangeLevel},model:{value:(_vm.formData.createNewType),callback:function ($$v) {_vm.$set(_vm.formData, "createNewType", $$v)},expression:"formData.createNewType"}})],1):_vm._e(),(_vm.settingType === 'NEW')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Account Name'))+" "),_c('b-badge',[_vm._v(_vm._s(_vm.formData.createNewType))])],1),_c('validation-provider',{attrs:{"name":"Account Name","rules":"required|min:1|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(['senior', 'partner'].some(function (list){ return list === _vm.formData.createNewType; }))?_c('b-input-group',[_c('b-form-input',{attrs:{"value":_vm.generateAccountName,"disabled":""}}),_c('b-input-group-append',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.onClickGenerateAccount($event)}}},[_vm._v(" Generate ")])],1)],1):_c('b-input-group',{attrs:{"prepend":String(_vm.prefix).toLowerCase()}},[_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Account Name'),"autocomplete":"off"},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2316222919)})],1)],1):_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Account Name'))+" "),_c('b-badge',[_vm._v(_vm._s(_vm.formData.createNewType))])],1),_c('validation-provider',{attrs:{"name":"Account Name","rules":"required|min:1|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"autocomplete":"off","disabled":""},model:{value:(_vm.formData.username),callback:function ($$v) {_vm.$set(_vm.formData, "username", $$v)},expression:"formData.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Contact')))]),_c('validation-provider',{attrs:{"name":"Contact","rules":"min:1|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Contact')},model:{value:(_vm.formData.contact),callback:function ($$v) {_vm.$set(_vm.formData, "contact", $$v)},expression:"formData.contact"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Password')))]),_c('validation-provider',{attrs:{"name":"Password","rules":_vm.passwordRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Password'),"type":"password","autocomplete":"off"},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Currency')))]),_c('v-select',{attrs:{"label":_vm.$t('Currency'),"options":['THB']},model:{value:(_vm.formData.currency),callback:function ($$v) {_vm.$set(_vm.formData, "currency", $$v)},expression:"formData.currency"}})],1)],1),(_vm.settingType==='NEW')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Credit')))]),_c('validation-provider',{attrs:{"name":"Credit","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Credit'),"type":"number"},model:{value:(_vm.formData.creditLimit),callback:function ($$v) {_vm.$set(_vm.formData, "creditLimit", _vm._n($$v))},expression:"formData.creditLimit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2260213858)})],1)],1):_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Credit')))]),_c('b-form-input',{attrs:{"value":_vm.formData.creditLimit,"placeholder":_vm.$t('Credit'),"type":"number","disabled":""}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('b-form-group',[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('MaxCreditLimit')))]),_c('validation-provider',{attrs:{"name":"MaxCreditLimit","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('MaxCreditLimit'),"type":"number"},model:{value:(_vm.formData.maxCreditLimit),callback:function ($$v) {_vm.$set(_vm.formData, "maxCreditLimit", _vm._n($$v))},expression:"formData.maxCreditLimit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),(_vm.settingType!=='NEW')?_c('b-row',{staticClass:"pb-1"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"switch":""},model:{value:(_vm.formData.active),callback:function ($$v) {_vm.$set(_vm.formData, "active", $$v)},expression:"formData.active"}},[_c('span',{staticClass:"h5 font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Suspend / Active')))])])],1)],1):_vm._e(),(_vm.settingType==='NEW')?_c('b-row',[_c('b-col',[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"switch":""},model:{value:(_vm.formData.requireChangePassword),callback:function ($$v) {_vm.$set(_vm.formData, "requireChangePassword", $$v)},expression:"formData.requireChangePassword"}},[_c('span',{staticClass:"h5 font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('Require ChangePassword')))])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }